.sort {
	border: 1px solid rgba(160, 89, 143, 1) !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 12px !important;
	// color: #fff;
	padding-left: 8px !important;
	background-color: whitesmoke !important;
	color: rgba(160, 89, 143, 1);
	cursor: pointer;
	user-select: none;
	outline: none;


	option {
		font-weight: 600;
		color: rgba(160, 89, 143, 1);
	}
}